const routeList = [
    {
        path: 'game',
        name: 'CryptoGames',
        meta: {
            title: 'Crypto Games'
        },
        component: () => import('@/pages/game/Index.vue'),
        children:[
            {
                path: '',
                name: 'QuizPageDefaulted',
                meta: {
                    title: 'Crypto Quiz'
                },
                component: () => import('@/pages/game/Quiz.vue'),
            },
            {
                path: 'quiz',
                name: 'QuizPage',
                meta: {
                    title: 'Crypto Quiz'
                },
                component: () => import('@/pages/game/Quiz.vue'),
            },
            {
                path: 'leaderboards',
                name: 'Leaderboard',
                meta: {
                    title: 'Leaderboards'
                },
                component: () => import('@/pages/game/Leaderboards.vue'),
            },
        ]
    },
]


export default {
    routes: routeList
}