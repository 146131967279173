const routeList = [
    {
        path: 'chart',
        name: 'ChartIndex',
        meta: {
            title: 'Charts'
        },
        component: () => import('@/pages/chart/Index.vue'),
        children:[

        ]
    },
]


export default {
    routes: routeList
}