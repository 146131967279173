import api from "@/api/api";
import store from "@/store";
import {bus} from "@/main";


const alertsModule = {
    state: () => ({
        alerts:null,
    }),
    getters: {
        alerts: (state)=>  {
            if (state.alerts === null) {
                state.alerts = {}
                fetchAlerts()
            }

            const itemsArray = Object.entries(state.alerts);

            // Sort the array based on whatever logic you need
            itemsArray.sort((a, b) => b[1].active_in.length - a[1].active_in.length);

            // Convert the sorted array back to an object
            return Object.fromEntries(itemsArray);

        },
        alert: (state)=> (alertId) => {
            if (alertId === ''){
                return {
                    name: "No Alert",
                    active_in:[],
                }
            }
            if (state.alerts === null) {
                state.alerts = {}
                fetchAlerts()
            }else{
                if (state.alerts[alertId]) {
                    return state.alerts[alertId]
                }
            }
            return {
                name: "...",
                active_in:[],
            }
        },
        refreshAlerts: ()=> ()=>  {
            fetchAlerts()
        },
    },
    mutations: {
        addAlerts (state, payload) {
            for (let cond of payload){
                let old = state.alerts[cond.id]
                if (old){
                    if (cond.is_active === true && old.is_active !== true || !areStringSlicesEqual(old.active_in, cond.active_in)){
                        notify()
                    }
                }
                if (!cond.active_in){
                    cond.active_in = []
                }
                state.alerts[cond.id] = cond

            }
        },
        setAlerts (state, payload) {
            state.alerts = {}
            for (let cond of payload){
                if (!cond.active_in){
                    cond.active_in = []
                }
                state.alerts[cond.id] = cond

            }
        },
    },
    actions: {
        addAlerts (context, payload) {
            context.commit("addAlerts", payload);
        },
        setAlerts (context, payload) {
            context.commit("setAlerts", payload);
        },
    }
}

export default alertsModule

let busInited = false

function fetchAlerts(){
    api.alert.list(
        (result) => {
            if (result.data.list) {
                store.dispatch('setAlerts', result.data.list).then(() => {})
            }
        },
        () => {}, {
            page: 0,
            items:999,
            term: "",
        }
    )

    if (!busInited){
        busInited = true
        bus.on("push_alerts", (data) => {
            for (let alertUpdate of data){
                let alert = alertUpdate.alert

                alert.active_in = alertUpdate.ticker
                if (typeof alert.active_in === 'undefined'){
                    alert.active_in = []
                }
                store.dispatch('addAlerts', [alert]).then(() => {})
            }
        })
    }

}

let audio = new Audio('static/audio/notify.mp3');
let playing = false
function notify(){
    if (playing){
        return
    }
    playing = true
    try{
        audio.play().then(() => {
            playing = false
        }).catch(()=>{
            playing = false
        });
    }catch (e){
        console.log(e)
    }
}

function areStringSlicesEqual(array1, array2) {
    if (typeof array1 !== typeof array2){
        return false
    }
    // Convert slices to arrays
    // Sort arrays
    array1.sort();
    array2.sort();

    return JSON.stringify(array1) === JSON.stringify(array2);
}