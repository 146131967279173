const routeList = [
    {
        path: 'markets',
        name: 'Markets',
        meta: {
            title: 'Markets'
        },
        component: () => import('@/pages/markets/Index.vue'),
    },
]


export default {
    routes: routeList
}