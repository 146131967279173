const routeList = [
    {
        path: 'orders',
        name: 'Orders',
        meta: {
            title: 'Orderbook Ratios'
        },
        component: () => import('@/pages/orders/Index.vue'),
    },
]


export default {
    routes: routeList
}