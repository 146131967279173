const routeList = [{
    path: 'alerts',
    name: 'Alerts',
    meta: {
        title: 'Alerts'
    },
    component: () => import('@/pages/alerts/Alerts.vue'),
}, {
    path: 'alerts/community',
    name: 'Community Alerts',
    meta: {
        title: 'Community Alerts'
    },
    component: () => import('@/pages/alerts/Community.vue'),
},
    {
    path: 'alerts/:alertId',
    name: 'Edit Alert',
    meta: {
        title: 'Manage Alert'
    },
    component: () => import('@/pages/alerts/EditAlert.vue'),
},
]


export default {
    routes: routeList
}