import { createApp } from 'vue'
import App from './App.vue'
import mitt from 'mitt';
import Toaster from '@meforma/vue-toaster';
import {routes} from "./router/routes";
import store from "@/store";
import { createWebHistory, createRouter } from "vue-router";
import api from "@/api/api";
import Highcharts from "highcharts";
import StockModule from "highcharts/modules/stock";
import HighchartsVue from "highcharts-vue";

export const bus = mitt()

const app = createApp(App)

const notification = ()=>{
    let audio = new Audio('static/audio/notify.mp3');
    try{
        audio.play().then(() => {});
    }catch (e){
        console.log(e)
    }
}

StockModule(Highcharts);

app.use(HighchartsVue);
//add global functions
app.config.globalProperties.$api = api
app.config.globalProperties.errorShower = (e) => {
    app.config.globalProperties.showError(e)
}
app.config.globalProperties.notification = notification
app.config.globalProperties.showError = (e) => {
    if (typeof e.response != "undefined") {
        let message = e.response.data;
        let onClick = null
        app.config.globalProperties.$toast.onClick = null;
        if (e.response.data.error) {
            let msgData = e.response.data.error;
            if (msgData.message) {
                message = msgData.message
                if (msgData.id) {
                    onClick = ()=>{
                        navigator.clipboard.writeText(msgData.id)
                        app.config.globalProperties.$toast.success("Error code copied to clipboard", {
                            max: 6,
                        })
                    };
                    message = message + "\n\nError code: " + msgData.id
                }
            }

        } else {
            message = e.response.data
        }
        app.config.globalProperties.$toast.error(message, {
            max: 6,
            onClick: onClick
        })
        if (e.response.status === 401) {
            document.location = '/login';
        }
    } else if (typeof e === 'string'){
        app.config.globalProperties.$toast.error(e, {
            max: 6,
        })
}else{
        console.log(e)
    }
}

app.config.globalProperties.showSuccess = (message) =>{
    app.config.globalProperties.$toast.success( message, {
        max: 6
    })
}

/* global bootstrap */
app.config.globalProperties.hideModal = (modalId) => {
    const modalElement = document.getElementById(modalId);
    const modalInstance = bootstrap.Modal.getInstance(modalElement);
    if (modalInstance) {
        modalInstance.hide();
    }
}
app.config.globalProperties.showModal = (modalId) => {
    const modalElement = document.getElementById(modalId);
    const modalInstance = new bootstrap.Modal(modalElement);
    if (modalInstance) {
        modalInstance.show();
    }
}


//setup router
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(), // <-- this is a new property it is mandatory!
    routes, // short for `routes: routes`
    linkExactActiveClass: 'active',
    scrollBehavior() {
        return new Promise(resolve => {
            setTimeout(() => {
              resolve({ top: 0 + '5rem', behavior: 'smooth' });
            }, 100);
          });
    }
})


app.use(router)
app.use(store)
app.use(Toaster)
app.mount('#app')
