import {createStore} from "vuex";
import appModule from "./modules/app";
import klinesModule from "./modules/klines";
import favoutiresModule from "@/store/modules/favourites";
import alertsModule from "@/store/modules/alerts";
import usersModule from "@/store/modules/users";

export default createStore({
    modules: {
        app: appModule,
        klines: klinesModule,
        conditions: favoutiresModule,
        alerts: alertsModule,
        users: usersModule,
    }
})
