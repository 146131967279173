const routeList = [{
    path: 'conditions',
    name: 'Conditions',
    meta: {
        title: 'Conditions'
    },
    component: () => import('@/pages/conditions/Conditions.vue'),
},
]


export default {
    routes: routeList
}