import {bus} from "@/main";
import store from "@/store";

let ws = null;
let connected = false;
let keepalive = null
let toSend = []
let closers = [] //array of close functions
let reconnect = () => {
    //close all previous connections
    for (let c of closers){
        try{
            c()
        }catch (e){
            console.log(e)
        }
    }
    closers = []//reset closers

    // if (store.state.app.user == null || (store.state.app.user && store.state.app.user.id === "" || connected === true)) {
    //     return //do not connect
    // }
    if (location.protocol === 'https:') {
        ws = new WebSocket("wss://" + location.host + "/websocket/");
    } else {
        ws = new WebSocket("ws://" + location.host + "/websocket/");
    }

    store.dispatch('wsState', 1).then(() => {
    });

    ws.onopen = onOpen

    ws.onmessage = onMsg

    ws.onclose = onClose
    closers.push(()=>{
        ws.onclose = null
        ws.close()
    })
}

function onMsg(event) {
    if (!event.data) {
        return
    }

    try {
        let wsMsg = JSON.parse(event.data);
        if (wsMsg.p) {
            let pay = JSON.parse(wsMsg.p)
            bus.emit("push_" + wsMsg.a, pay);
        }
    } catch (e) {
        console.log(e)
    }
}

function onOpen() {
    store.dispatch('wsState', 2).then(() => {
    });
    connected = true
    resub()
    keepalive = setInterval(() => {
        send("p")
    }, 20000);
    for (let p of toSend){
        send(p)
    }
    toSend = []
}

function onClose() {
    connected = false
    privateSubsIndex = {}
    publicSubsIndex = {}
    store.dispatch('wsState', 0).then(() => {
    });
    if (keepalive) {
        clearInterval(keepalive)
    }
    setTimeout(function () {
        reconnect()
    }, 5000)
}

function send(data) {
    if (!connected) {
        return
    }
    try {
        ws.send(JSON.stringify(data))
    } catch (i) {
        console.log(i)
    }
}

let resubTimer = null

function resub() {
    if (resubTimer !== null) {
        clearTimeout(resubTimer)
    }
    resubTimer = setTimeout(doresub, 1000)
}

function doresub() {
    let newPrivateIn = [] //new subscribes private
    let newPrivateOut = []//new subscribes public
    let newPublicIn = []//new unsubscribes private
    let newPublicOut = []//new unsubscribes public

    for (let who in subscriptions) {
        //discover new subscriptions
        for (let topic in subscriptions[who]) {
            let isPrivate = subscriptions[who][topic]
            if (isPrivate) {
                if (!privateSubsIndex[topic]) {
                    newPrivateIn.push(topic)
                    privateSubsIndex[topic] = true
                }
            } else {
                if (!publicSubsIndex[topic]) {
                    newPublicIn.push(topic)
                    publicSubsIndex[topic] = true
                }
            }
        }

    }

    //discover removed subscriptions
    for (let existingTopic in privateSubsIndex) {
        let exists = false;
        for (let who in subscriptions) {
            if (typeof (subscriptions[who][existingTopic]) !== "undefined") {
                exists = true
                break
            }
        }
        if (!exists) {
            newPrivateOut.push(existingTopic)
            delete privateSubsIndex[existingTopic]
        }
    }
    for (let existingTopic in publicSubsIndex) {
        let exists = false;
        for (let who in subscriptions) {
            if (typeof (subscriptions[who][existingTopic]) !== "undefined") {
                exists = true
                break
            }
        }
        if (!exists) {
            newPublicOut.push(existingTopic)
            delete publicSubsIndex[existingTopic]
        }
    }


    send({
        "a": "channels",
        "p": JSON.stringify({
            us: newPrivateIn,
            uu: newPrivateOut,
            s: newPublicIn,
            u: newPublicOut,
        })
    })
}

let subscriptions = {}
let privateSubsIndex = {}
let publicSubsIndex = {}


let connection = {
    connect: () => {
        reconnect()
    },
    subscribe(componentId, topic, isPrivate) {
        if (!Object.prototype.hasOwnProperty.call(subscriptions, componentId)) {
            subscriptions[componentId] = {}
        }

        let psWho = subscriptions[componentId]
        if (Object.prototype.hasOwnProperty.call(psWho, topic)) {
            return //already subscribed
        }

        psWho[topic] = isPrivate
        resub()
    },
    unsubscribe(componentId, topic) {
        if (!Object.prototype.hasOwnProperty.call(subscriptions, componentId)) {
            return
        }

        let psWho = subscriptions[componentId]
        if (Object.prototype.hasOwnProperty.call(psWho, topic)) {
            delete psWho[topic]
        }
        resub()
    },
    send: (topic, payload) => {
        if (!connected){
            toSend.push({
                "a": topic,
                "p": JSON.stringify(payload)
            })
        }else{
            send({
                "a": topic,
                "p": JSON.stringify(payload)
            })
        }
    },
    unsubscribeAllFor(componentId) {
        delete subscriptions[componentId]
    }
}

export default connection