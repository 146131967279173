const routeList = [
    {
        path: '',
        name: 'Dashboard',
        meta: {
            title: 'Dashboard'
        },
        component: () => import('@/pages/member/Dashboard.vue'),
    },
]


export default {
    routes: routeList
}