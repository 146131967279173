const routeList = [
    {
        path: '',
        name: 'Ops',
        component: () => import('@/pages/ops/Index.vue'),
        children:[
            {
                path: '',
                name: 'OpsMenu',
                meta: {
                    title: 'Ops'
                },
                component: () => import('@/pages/ops/Menu.vue'),
            },
            {
                path: 'twitter',
                name: 'TwitterOps',
                meta: {
                    title: 'Twitter'
                },
                component: () => import('@/pages/ops/Twitter.vue'),
            },
            {
                path: 'tests',
                name: 'TestPage',
                meta: {
                    title: 'Test Page'
                },
                component: () => import('@/pages/ops/Tests.vue'),
            },
            {
                path: 'users',
                name: 'UsersOps',
                meta: {
                    title: 'Users'
                },
                component: () => import('@/pages/ops/Users.vue'),
            },
            {
                path: 'codes',
                name: 'CodesOps',
                meta: {
                    title: 'Codes'
                },
                component: () => import('@/pages/ops/Codes.vue'),
                children:[
                    {
                        path: '',
                        name: 'CodesList',
                        meta: {
                            title: 'List of codes'
                        },
                        component: () => import('@/pages/ops/codes/List.vue'),
                    },
                    {
                        path: 'create',
                        meta: {
                            title: 'Create codes'
                        },
                        component: () => import('@/pages/ops/codes/Create.vue'),
                    },
                ],
            },
            {
                path: 'user/:userId',
                name: 'UserOps',
                meta: {
                    title: 'User Settings',
                    backTo: [{
                        route: "/ops/users",
                        title: "Users"
                    }]
                },
                component: () => import('@/pages/ops/user/Index.vue'),
                children:[
                    {
                        path: '',
                        name: 'UserDetails',
                        meta: {
                            title: 'Details'
                        },
                        component: () => import('@/pages/ops/user/Details.vue'),
                    },
                    {
                        path: 'alerts',
                        name: 'UserAlerts',
                        meta: {
                            title: 'Alerts'
                        },
                        component: () => import('@/pages/ops/user/Alerts.vue'),
                    },
                    {
                        path: 'subscription',
                        name: 'UserSubscription',
                        meta: {
                            title: 'Subscription'
                        },
                        component: () => import('@/pages/ops/user/Subscription.vue'),
                    },
                    {
                        path: 'notifiers',
                        name: 'UserNotifiers',
                        meta: {
                            title: 'Notifiers'
                        },
                        component: () => import('@/pages/ops/user/Notifiers.vue'),
                    },
                ]
            },
            {
                path: 'conditions',
                name: 'ConditionsOps',
                meta: {
                    title: 'Conditions'
                },
                component: () => import('@/pages/ops/Conditions.vue'),
            },
        ]
    },
]


export default {
    routes: routeList
}