const routeList = [
    {
        path: '',
        name: 'Bitcoinian',
        meta: {
            title: 'Bitcoinian'
        },
        component: () => import('@/pages/public/Home.vue'),
    },
    {
        path: 'login',
        name: 'Login',
        meta: {
            title: 'Account login'
        },
        component: () => import('@/pages/public/Login.vue'),
    },
    {
        path: 'register',
        name: 'Register',
        meta: {
            title: 'Sign up'
        },
        component: () => import('@/pages/public/Register.vue'),
    },
    {
        path: 'reset',
        name: 'Reset',
        meta: {
            title: 'Request password reset'
        },
        component: () => import('@/pages/public/Reset.vue'),
    },
    {
        path: 'privacy',
        name: 'Privacy',
        meta: {
            title: 'Privacy Policy'
        },
        component: () => import('@/pages/public/Privacy.vue'),
    },
    {
        path: 'tos',
        name: 'TOS',
        meta: {
            title: 'Terms of service'
        },
        component: () => import('@/pages/public/Tos.vue'),
    },
    {
        path: 'About',
        name: 'Impressum',
        meta: {
            title: 'Bitcoinain UG'
        },
        component: () => import('@/pages/public/About.vue'),
    },
    {
        path: 'rules',
        name: 'CommunityRules',
        meta: {
            title: 'Community Rules'
        },
        component: () => import('@/pages/public/Rules.vue'),
    },
    {
        path: 'cookies',
        name: 'CookiesPolicy',
        meta: {
            title: 'Cookies Policy'
        },
        component: () => import('@/pages/public/Cookies.vue'),
    },
    {
        path: 'contact',
        name: 'ContactUs',
        meta: {
            title: 'Contact Us'
        },
        component: () => import('@/pages/public/Contact.vue'),
    },
    {
        path: 'features',
        name: 'FeaturesPage',
        meta: {
            title: 'Features'
        },
        component: () => import('@/pages/public/Features.vue'),
    },
    {
        path: 'social',
        name: 'SocialPage',
        meta: {
            title: 'Social Networks'
        },
        component: () => import('@/pages/public/Social.vue'),
    },
    {
        path: 'disclaimer',
        name: 'DisclaimerPage',
        meta: {
            title: 'Disclaimer'
        },
        component: () => import('@/pages/public/Disclaimer.vue'),
    },
    {
        path: 'refer',
        name: 'ReferPage',
        meta: {
            title: 'Refer a friend'
        },
        component: () => import('@/pages/public/Refer.vue'),
    },
    {
        path: 'pricing',
        name: 'PricingPage',
        meta: {
            title: 'Pricing'
        },
        component: () => import('@/pages/public/Pricing.vue'),
    },
    {
        path: 'alerts',
        name: 'AlertsPage',
        meta: {
            title: 'Alerts'
        },
        component: () => import('@/pages/public/Alerts.vue'),
    },
    {
        path: 'hmc',
        name: 'heatmapCrypto',
        meta: {
            title: 'Crypto Heatmap'
        },
        component: () => import('@/pages/public/HeatMapCrypto.vue'),
    },
    {
        path: 'news',
        name: 'NewsPage',
        meta: {
            title: 'News'
        },
        component: () => import('@/pages/public/News.vue'),
    },
    {
        path: 'ccs',
        name: 'CoinsCryptoScreener',
        meta: {
            title: 'News'
        },
        component: () => import('@/pages/public/CoinsCryptoScreener.vue'),
    },
    {
        path: 'orderbook-ratio',
        name: 'OrderBookRatio',
        meta: {
            title: 'Orderbook Ratios'
        },
        component: () => import('@/pages/public/OrderbookRatio.vue'),
    },
    {
        path: 'moderators',
        name: 'ModeratorsPage',
        meta: {
            title: 'Moderators'
        },
        component: () => import('@/pages/public/Moderators.vue'),
    },
]


export default {
    routes: routeList
}