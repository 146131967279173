import api from "@/api/api";
import store from "@/store";


const usersModule = {
    state: () => ({
        users:{},
    }),
    getters: {
        userProfile: (state)=> (userId)=> {
            if (state.users[userId]) {
                return state.users[userId]
            }
            fetchUser(userId)
            return {
                name: "Unknown",
            }
        },
    },
    mutations: {
        addUsers (state, payload) {
            for (let user of payload){
                state.users[user.id] = user

            }
        },
    },
    actions: {
        addUsers (context, payload) {
            context.commit("addUsers", payload);
        },
    }
}

export default usersModule

let userFetchTimer = null
let userToFetchIds = [];

function fetchUser(userId){
    if(userFetchTimer!= null){
        clearTimeout(userFetchTimer)
    }

    userToFetchIds.push(userId)

    userFetchTimer = setTimeout(doFetch, 300);
}

function doFetch(){
    api.auth.public.profiles(
        (result) => {
            if (result.data.list) {
                store.dispatch('addUsers', result.data.list).then(() => {
                })
            }
        },
        () => {},
        userToFetchIds
    )
}