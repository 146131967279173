import api from "@/api/api";
import store from "@/store";


const klinesModule = {
    state: () => ({
        conditionConfig: null,
        exchanges: [],
        symbols: [],
        intervals: [],
        patterns: {},
        indicators: {},
        structures: {},
        tiers: [],
        symbolDetails:{},
        tickers: {},
    }),
    getters: {
        tickers: (state) => {
            if (Object.keys(state.tickers).length === 0) {
                fetchConfig()
            }
            return state.tickers
        },
        ticker: (state) => (t) => {
            if (Object.keys(state.tickers).length === 0) {
                fetchConfig()
                return {
                    b: "...",
                }
            }
            if (!state.tickers[t]){
                return {
                    b: "...",
                }
            }
            return state.tickers[t]
        },
        conditionConfig: (state) => {
            if (state.conditionConfig === null) {
                state.conditionConfig = {}
                fetchConfig()
            }
            return state.conditionConfig
        },
        exchanges: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.exchanges
        },
        patterns: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.patterns
        },
        symbols: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.symbols
        },
        symbol: (state) => (ticker) => {
            if (state.symbolDetails.length === 0) {
                fetchConfig()
            }
            if (state.symbolDetails[ticker]){
                return state.symbolDetails[ticker]
            }
            return {

            }
        },
        exchangeSymbols: (state) => (exchange) => {
            if (state.symbolDetails.length === 0) {
                fetchConfig()
            }

            let symbols = []
            for (let k in state.symbolDetails){
                for (let e of state.symbolDetails[k].e){
                    if (e === exchange){
                        symbols.push(k)
                    }
                }

            }

            return symbols
        },
        indicators: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.indicators
        },
        indicator: (state) => (type) =>{
            if (state.indicators[type]) {
                return state.indicators[type]
            }
            return {
                name: 'Unknown'
            }
        },
        structure: (state) => (type) =>{
            if (state.structures[type]) {
                return state.structures[type]
            }
            return {
                name: 'Unknown'
            }
        },
        pattern: (state) => (type) =>{
            if (state.patterns[type]) {
                return state.patterns[type]
            }
            return {
                name: 'Unknown'
            }
        },
        structures: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.structures
        },
        structuresAndIndicators: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return {...state.indicators, ...state.structures}
        },
        intervals: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.intervals
        },
        tiers: (state) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.tiers
        },
        priceTypes: () => {
            return ["close", "open", "high", "low"]
        },
        volumeTypes: () => {
            return {
                "base": "Base",
                "asset": "Asset",
                "taker_base": "Taker Base",
                "taker_asset": "Taker Asset",
            }
        },
        ops: (state) => (inputType) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            return state.conditionConfig[inputType]
        },
        input2: (state) => (inputType, op) => {
            if (state.conditionConfig === null) {
                fetchConfig()
            }
            if (state.conditionConfig[inputType]) {
                if (state.conditionConfig[inputType][op]) {
                    return state.conditionConfig[inputType][op]
                }
            }
            return {}
        },
    },
    mutations: {
        updateConfig(state, payload) {
            state.conditionConfig = payload["conditionConfig"];
            state.exchanges = payload["exchanges"];
            state.symbols = payload["symbols"];
            state.intervals = payload["intervals"];
            state.patterns = payload["patterns"];
            state.indicators = payload["indicators"];
            state.structures = payload["structures"];
            state.tiers = payload["tiers"];
            state.symbolDetails = payload['symbol_details']
        },
        setTickers(state, payload) {
            for (let pid in payload) {
                let s = payload[pid].t
                if (!state.tickers[s]) {
                    state.tickers[s] = payload[pid]
                }
                if (parseFloat(payload[pid].c) > parseFloat(state.tickers[s].c)) {
                    state.tickers[s].up = true
                    state.tickers[s].down = false
                }
                if (parseFloat(payload[pid].c) < parseFloat(state.tickers[s].c)) {
                    state.tickers[s].down = true
                    state.tickers[s].up = false
                }
                state.tickers[s].c = payload[pid].c
                state.tickers[s].ch = ((payload[pid].c - payload[pid].h) / payload[pid].h) * 100
                state.tickers[s].cd = ((payload[pid].c - payload[pid].d) / payload[pid].d) * 100
                state.tickers[s].cw = ((payload[pid].c - payload[pid].w) / payload[pid].w) * 100
            }
        },

    },
    actions: {
        updateConfig(context, payload) {
            context.commit("updateConfig", payload);
        },
        setTickers(context, payload) {
            context.commit("setTickers", payload);
        },
    }
}

export default klinesModule

let fetching = false

function fetchConfig() {
    if (fetching) {
        return
    }
    fetching = true
    api.market.config(
        (result) => {
            if (result.data) {
                store.dispatch('updateConfig', result.data).then(() => {
                })
            }
            fetching = false
        },
        () => {
            fetching = false
        },
    )
    api.market.list.tickers(
        (result) => {
            if (result.data && result.data.tickers) {
                store.dispatch('setTickers', result.data.tickers).then(() => {
                })
            }
            fetching = false
        },
        () => {
            fetching = false
        },
    )
}