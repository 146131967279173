import api from "@/api/api";
import store from "@/store";
import {bus} from "@/main";
import appModule from "@/store/modules/app";

const conditionsModule = {
    state: () => ({
        conditions:{},
        favConditions:null,
        favTickers:null,
    }),
    getters: {
        condition: (state)=> (id) => {
            if (!state.conditions[id]) {
                state.conditions[id] = {
                    name: "..."
                }
                toFetch(id)
            }
            return state.conditions[id]
        },
        favouriteTickers: (state)=>  {
            if (state.favTickers === null){
                refreshFavs()
                return []
            }
            return state.favTickers
        },
        favouriteConditions: (state) => {
            if (state.favConditions === null){
                refreshFavs()
                return []
            }
            return state.favConditions
        },
        isFavouriteCondition: (state)=> (id) => {
            if (state.favConditions === null){
                refreshFavs()
                return false
            }
            for (let fc of state.favConditions){
                if (fc.condition_id === id){
                    return true
                }
            }
            return false
        },
        isFavouriteTicker: (state)=> (exchange, ticker) => {
            if (state.favTickers === null){
                refreshFavs()
                return false
            }
            for (let fc of state.favTickers){
                if (fc.exchange === exchange && fc.ticker === ticker){
                    return true
                }
            }
            return false
        },
    },
    mutations: {
        addConditions (state, payload) {
            for (let cond of payload){
                state.conditions[cond.id] = cond
            }
        },
        setConditionFavourites (state, payload) {
            state.favConditions = payload
        },
        setTickerFavourites (state, payload) {
            state.favTickers = payload
        },
        updateConditionSymbols (state, payload) {
            for (let condId in payload){
                if (state.conditions[condId]){
                    state.conditions[condId].markets = payload[condId]
                }
            }

        },
    },
    actions: {
        addConditions (context, payload) {
            context.commit("addConditions", payload);
        },
        setConditionFavourites (context, payload) {
            context.commit("setConditionFavourites", payload);
        },
        updateConditionSymbols (context, payload) {
            context.commit("updateConditionSymbols", payload);
        },
        setTickerFavourites (context, payload) {
            context.commit("setTickerFavourites", payload);
        },
    }
}

export default conditionsModule

let fetchTimer = null
let toFetchIds = [];

function toFetch(id){
    if(fetchTimer!= null){
        clearTimeout(fetchTimer)
    }

    toFetchIds.push(id)

    fetchTimer = setTimeout(doFetch, 300);
}


function doFetch(){
    api.kline.conditions.listIds(
        (result) => {
            if (result.data.list) {
                store.dispatch('addConditions', result.data.list).then(() => {
                })
            }
        },
        () => {},
        toFetchIds
    )
}
let favBusInited = false
let isRefreshing = false
function refreshFavs(){

    if (isRefreshing === true || !appModule.getters['loggedIn']){
        return
    }
    isRefreshing = true
    api.favourite.condition.list(
        (result) => {
            if (result.data.list) {
                store.dispatch('setConditionFavourites', result.data.list).then(() => {})
            }
            isRefreshing = false
        },
        () => {
            isRefreshing = false
        },
        toFetchIds
    )

    api.favourite.ticker.list(
        (result) => {
            if (result.data.list) {
                store.dispatch('setTickerFavourites', result.data.list).then(() => {})
            }
            isRefreshing = false
        },
        () => {
            isRefreshing = false
        },
        toFetchIds
    )

    if (!favBusInited){
        favBusInited = true
        bus.on("push_REFRESH_FAV_COND", () => {
            refreshFavs()
        })
        bus.on("push_REFRESH_FAV_TICKER", () => {
            refreshFavs()
        })
        bus.on("push_FAVCONDSYMUP", (data) => {
            store.dispatch('updateConditionSymbols', data).then(() => {})
        })
    }
}