const routeList = [
    {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/pages/settings/Index.vue'),
        children:[
            {
                path: '',
                name: 'Settings',
                meta: {
                    title: 'Settings'
                },
                component: () => import('@/pages/settings/Menu.vue'),
            },
            {
                path: 'telegram',
                name: 'TelegramSettings',
                meta: {
                    title: 'Telegram Settings'
                },
                component: () => import('@/pages/settings/Telegram.vue'),
            },
            {
                path: 'pro',
                name: 'ProSettings',
                meta: {
                    title: 'Bitcoinian Pro'
                },
                component: () => import('@/pages/settings/Pro.vue'),
            },
            {
                path: 'invoices',
                name: 'Invoices',
                meta: {
                    title: 'Invoices'
                },
                component: () => import('@/pages/settings/Invoices.vue'),
            },
            {
                path: 'referrals',
                name: 'Referrals',
                meta: {
                    title: 'Referrals & Perks'
                },
                component: () => import('@/pages/settings/Referrals.vue'),
            },
            {
                path: 'redeem',
                name: 'RedeemCode',
                meta: {
                    title: 'Redeem Subscription Code'
                },
                component: () => import('@/pages/settings/Redeem.vue'),
            },
        ]
    },
]


export default {
    routes: routeList
}