<template>
  <button id="scrollTopBtn" class="btn p-2 py-1 position-fixed end-0 me-2 mb-2"
          v-show="isVisible" @click="scrollToTop">
    <i class="bi bi-arrow-up-circle-fill text-info fs-2 fw-bold"></i>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isVisible = window.scrollY > 100;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
  #scrollTopBtn {
    bottom: 3rem;
  }
</style>
