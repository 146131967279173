const routeList = [
    {
        path: 'backtest',
        name: 'StrategyIndex',
        meta: {
            title: 'Back-testing'
        },
        component: () => import('@/pages/backtest/Index.vue'),
        children:[
            {
                path: '',
                name: 'StrategyList',
                meta: {
                    title: 'Back-testing strategies'
                },
                component: () => import('@/pages/backtest/List.vue'),
            },
            {
                path: 'history',
                name: 'BacktestHistory',
                meta: {
                    title: 'Back-testing history'
                },
                component: () => import('@/pages/backtest/History.vue'),
            },
            {
                path: 'details/:id?',
                name: 'StrategyPage',
                meta: {
                    title: 'Back-test'
                },
                component: () => import('@/pages/backtest/Backtest.vue'),
            },
            {
                path: 'details/:strategy_id/backtest/:backtest_id',
                name: 'BacktestDetails',
                meta: {
                    title: 'Back-test details'
                },
                component: () => import('@/pages/backtest/BacktestDetails.vue'),
            },
        ]
    },
]


export default {
    routes: routeList
}