<template>
  <router-view></router-view>
  <ScrollToTop />
</template>

<script>

import ws from "@/ws/ws";
import {mapGetters} from "vuex";
import {bus} from "@/main";
import ScrollToTop from './pages/components/ScrollToTop.vue';


export default {
  name: 'App',
  components: {
    ScrollToTop
  },
  data() {
    return {
      refresher: null,
    }
  },
  computed: {
    ...mapGetters(
        {
          loggedIn: 'loggedIn',
        }
    ),
  },
  watch:{
    'loggedIn'(){
      if (this.loggedIn === true){
        if (this.refresher === null) {
          this.refresher = setInterval(() => {
            this.$api.auth.refresh(() => {
            }, () => {
            })
          }, 60*1000 * 11) //refresh every 11 minutes
        }
      }else{
        if (this.refresher != null){
          clearInterval(this.refresher)
          this.refresher = null
        }
      }
    }
  },
  methods:{
    refreshUser(){
      this.$api.auth.user((res) => {
        this.$store.dispatch('setUser', res.data).then(() => {
        })
      }, () => {})
    }
  },
  mounted() {
    ws.connect()
    this.refreshUser()

    bus.on("push_refresh_user", () => {
      this.$api.auth.refresh(()=>{
        setTimeout(this.refreshUser, 1000)
      }, this.errorShower)
    })

    bus.on("push_TICKERS", (c) => {
      this.$store.dispatch('setTickers', c).then(() => {})
    })

  }
}
</script>
