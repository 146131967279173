import ws from "@/ws/ws";
import store from "@/store";
import $api from "@/api/api";

const appModule = {
    state: () => ({
        user:null,
        websocket: 0,
        plans:null,
    }),
    getters: {
        user: (state)=>  {
            return state.user
        },
        plans: (state)=>  {
            if (state.plans === null){
                fetchPlans()
                return []
            }
            return state.plans
        },
        loggedIn: (state)=>  {
            return state.user != null && state.user.id !== ""
        },
        isUser: (state)=>  {
            return state.user != null && state.user.id !== "" && state.user.ops === "u"
        },
        wsState: (state)=>  {
            return state.websocket
        },
    },
    mutations: {
        setUser (state, payload) {
            state.user = payload;
            if (store.state.websocket !== 2){
                ws.connect()
            }
        },
        wsState (state, payload) {
            state.websocket = payload;
        },
        setPlans (state, payload) {
            state.plans = payload;
        },
    },
    actions: {
        setUser (context, payload) {
            context.commit("setUser", payload);
        },
        wsState (context, payload) {
            context.commit("wsState", payload);
        },
        setPlans (context, payload) {
            context.commit("setPlans", payload);
        },
    }
}

export default appModule

function fetchPlans(){
    $api.bill.plans((e) => {
        store.dispatch('setPlans', e.data.plans).then(() => {})
    }, ()=>{})
}
